/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-slash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.05 3.555A2 2 0 012 2h12a2 2 0 011.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 002 14h6.256A4.5 4.5 0 018 12.5a4.49 4.49 0 011.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0012.5 8a4.5 4.5 0 00-1.965.45l-.338-.207z"/><path pid="1" d="M14.975 10.025a3.5 3.5 0 10-4.95 4.95 3.5 3.5 0 004.95-4.95m-4.243.707a2.5 2.5 0 013.147-.318l-3.465 3.465a2.5 2.5 0 01.318-3.147m.39 3.854l3.464-3.465a2.501 2.501 0 01-3.465 3.465z"/>',
    },
});
